import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { motion } from "framer-motion";
import "../components/css/upcoming.css";


import rr from './img/upcoming/launch icon (1).webp';
import bg from './img/upcoming/launch icon (2).webp';


const UpcomingPage = () => {

  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);


  return (
    <div className="upcoming-container container-fluid p-0 position-relative">


      <div className="overlay-text">
        <div className="launch-upcoming d-flex">

          <h2>Launch </h2>
          <img src={rr} className="rocket-img" />
        </div>
        <h1>Coming Soon!</h1>
        <h3>We're working on our new website</h3>
      </div>

    </div>
  );
};

export default UpcomingPage;
